/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ahora que llega el calorcito es importante tener una idea de las temperaturas que alcanza nuestra cpu, para ello tenemos que usar el programa lm-sensors:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">aptitude install lm-sensors</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "Una vez instalado necesitaremos activar el modulo de los drivers de nuestra placa base, estos drivers se encargan de monitorizar los sensores mediante el bus I2C, este es un bus ampliamente utilizado en electronica para el contol de dispositivos de todo tipo. Necesitamos saber que drivers necesita nuestra placa base, para esto primero detectamos nuestros sensores con:"), "\n", React.createElement(_components.p, null, "(Como root)"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sensors-detect</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "A todas las preguntas respondemos yes. Y al final del todo nos dirá el módulo que tenemos que activar."), "\n", React.createElement(_components.p, null, "En mi caso el modulo es el it87, con modprobe lo activamos."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">modprobe it87</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "Con el comando sensors podemos ver la salida de nuestros sensores."), "\n", React.createElement(_components.p, null, "Ahora podemos utilizar cualquier programa de visualización de sensores en nuestro linux, yo he utilizado el widget de Kde4."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
